
//原型组field
export const protoGroupListField = [
  {
    label: '分组名称',
    prop: 'name',
    colNum: 6,
    placeholder: '请输入分组名称'
  },
  {
    label: '款式',
    prop: 'styleName',
    colNum: 6,
    placeholder: '请输入款式名称'
  },
  {
    label: '状态',
    prop: 'enable',
    colNum: 6,
    slotName: 'enableSlot',
    placeholder: '请选择状态'
  },
  {
    label: '',
    prop: 'done',
    name: 'done',
    colNum: 1,
    slotName: 'doneSlot'
  }
]

